




























































import ArticlesListPanel from '@/components/HelpMessages/ArticlesListPanel.vue'
import { ArticlesState } from "@/store/articles/state";

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

import Component from 'vue-class-component'
import { OutputData } from '@editorjs/editorjs'
import Vue from 'vue'
import moment from "moment";
import { ExportToCsv, Options } from "export-to-csv";

@Component({
  components: {
    PageTitle,
    EmptyData
  },
  data() {
    return {
      ArticlesListPanel
    }
  },
})

export default class ArticlesList extends Vue {

  get articles(): ArticlesState['articles'] {
    return this.$store.state.articlesState.articles
  }

  toCreateNewArticle(): void {
    this.$router.push({
      name: 'article',
      params: {
        actionType: 'new'
      }
    })
  }

  toEditArticle(key: string): void {
    this.$router.push({
      name: 'article',
      params: {
        actionType: 'edit',
      },
      query: { articleKey: key },
    })
  }

  removeArticleWarn(key: string): void {
    this.$confirm({
      title: this.$t('delete').toString(),
      content: this.$t('delete_article_item').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      centered: true,
      onOk: () => {
        this.removeArticle(key)
      }
    })
  }

  removeArticle(key: string): void {
    this.$baseTemplate.loader.open()

    this.$store.dispatch('removeArticle', key)
      .finally(() => {
        this.$baseTemplate.loader.close()
      })
  }

  findFirstTextInArticle(article: OutputData): string {
    let p = {
      index: article.blocks.findIndex(item => item.type == 'paragraph'),
      item: article.blocks.find(item => item.type == 'paragraph')
    }
    let h = {
      index: article.blocks.findIndex(item => item.type == 'header'),
      item: article.blocks.find(item => item.type == 'header')
    }
    if (p.item && h.item) {
      if (p.index > h.index) {
        return this.abbreviation(p.item.data.text)
      } else {
        return this.abbreviation(h.item.data.text)
      }
    } else if (p.item && !h.item) {
      return this.abbreviation(p.item.data.text)
    } else if (!p.item && h.item) {
      return this.abbreviation(h.item.data.text)
    } else {
      return ''
    }
  }

  abbreviation(text: string): string {
    const formattedText = text.replaceAll(`<br>`, ' ')
    const parsedText = new DOMParser().parseFromString(formattedText, 'text/html').body.innerText

    if (parsedText.length > 300) {
      return parsedText.slice(0, 297) + '...'
    } else {
      return parsedText
    }
  }

  getTable(): void {
    const options: Options = {
      'fieldSeparator': ';',
      'quoteStrings': '"',
      'decimalSeparator': '.',
      'showLabels': true,
      'showTitle': true,
      'filename': this.$t('article_csv_table').toString(),
      'title': this.$t('article_csv_table').toString(),
      'useTextFile': false,
      'useBom': true,
      'useKeysAsHeaders': true,
      'headers': undefined,
    }

    const data = (this.articles ?? []).map((a) => {
      const text = this.findFirstTextInArticle(a.article)

      return {
        [this.$t('article_csv_title').toString()]: a.title,
        [this.$t('article_csv_description').toString()]: text.length > 300 ? `${ text.slice(0, 97) }...` : text,
        [this.$t('article_csv_create_date').toString()]: moment(a.article.time).format('LLL')
      }
    })

    new ExportToCsv(options).generateCsv(data)
  }

  mounted() {
    this.$baseTemplate.loader.open()

    this.$store.dispatch('requestArticles')
      .finally(() => this.$baseTemplate.loader.close())
  }
}
